import React, { ReactNode } from "react";

interface iLoaderProps {
  children?: ReactNode;
}

function Loader({ children }: iLoaderProps) {
  return (
    <React.Fragment>
      <div className="loader-bg">
        <div className="loader-track">
          <div className="loader-fill" />
        </div>
      </div>
      {children}
    </React.Fragment>
  );
}

export default Loader;
