import React from "react";

// const SignUp1 = React.lazy(() => import('./Pages/Authentication/SignUp/SignUp1'));
const SignIn = React.lazy(() => import("./Pages/Authentication/SignIn/SignIn"));
const SignOut = React.lazy(
  () => import("./Pages/Authentication/SignOut/SignOut")
);
const OrdersCards = React.lazy(() => import("./Pages/Orders/Cards"));

export const publicRoutes = {
  // Signup: { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
  SignIn: {
    path: "/auth/signin",
    exact: true,
    name: "Signin 1",
    component: SignIn,
  },
  SignOut: {
    path: "/auth/signout",
    exact: true,
    name: "SignOut",
    component: SignOut,
  },
  OrdersPrint: {
    path: "/pedidos/tarjetas/:ids",
    exact: true,
    name: "Tarjetas de Pedidos",
    component: OrdersCards,
  },
};

const routes = Object.values(publicRoutes);

export default routes;
